import {EnveloppeState} from "./enveloppe.models";
import {Action, createReducer, on} from "@ngrx/store";
import {
    demandeContactEnveloppeExpiree,
    demandeContactEnveloppeExpireeError,
    demandeContactEnveloppeExpireeSuccess,
    loadEnveloppeAction,
    loadEnveloppeErrorAction,
    loadEnveloppeSuccessAction,
    popEnveloppeMobileToolbarState,
    pushEnveloppeMobileToolbarState,
    setEtape,
    setEtapeOtp,
    setEtatEnveloppe,
    setFichierOpened,
    setFichierRead,
    setHasSigned,
    setIsSignataire,
    setIsMobile,
    setJustSigned,
    setMaintenanceEnveloppe,
    setSession,
    setSessionUid,
    setUid,
    updateForms,
    updateFormsError,
    updateFormsSuccess,
    updateSignatairesEnveloppe,
    updateSignataireEnveloppe,
    setSessionConflict, setSessionRefreshing
} from "./enveloppe.actions";

export const initialState: EnveloppeState = {
    uid: null,
    enveloppeInfos: null,
    loadingInfosEnveloppe: null,
    loadingFormUpdate: false,
    errorInfosEnveloppe: null,
    maintenanceEnveloppe: false,
    mobile: false,
    etapeOtp: false,
    justSigned: false,
    loadingDemandeContactEnveloppeExpiree: false,
    demandeContactEnveloppeExpireeSuccess: false,
    demandeContactEnveloppeExpireeError: null,
    sessionConflict: false,
    sessionRefreshing: false,
    mobileToolbarState: [{
        showBack: false
    }]
};

const reducer = createReducer(
    initialState,
    on(loadEnveloppeAction, (state) => ({
        ...state,
        ...{
            loadingInfosEnveloppe: true,
            errorInfosEnveloppe: null
        }
    })),

    on(loadEnveloppeSuccessAction, (state, action) => ({
        ...state,
        ...{
            loadingInfosEnveloppe: false,
            enveloppeInfos: action.data
        }
    })),

    on(loadEnveloppeErrorAction, (state, action) => ({
        ...state,
        ...{
            loadingInfosEnveloppe: false,
            errorInfosEnveloppe: action.error
        }
    })),

    on(setMaintenanceEnveloppe, (state, action) => ({
        ...state,
        ...{
            maintenanceEnveloppe: action.maintenanceEnveloppe
        }
    })),

    on(setEtapeOtp, (state, action) => ({
        ...state,
        ...{
            etapeOtp: action.etapeOtp
        }
    })),

    on(setJustSigned, (state, action) => ({
        ...state,
        ...{
            justSigned: action.justSigned
        }
    })),

    on(setSessionUid, (state, action) => ({
        ...state,
        ...{
            enveloppeInfos: {
                ...state.enveloppeInfos,
                session: {
                    ...state.enveloppeInfos?.session,
                    uid: action.uid
                }
            }
        }
    })),
    on(setSessionConflict, (state, action) => ({
        ...state,
        ...{
            sessionConflict: action.conflict
        }
    })),
    on(setIsMobile, (state, action) => ({
        ...state,
        mobile: action.isMobile
    })),
    on(setEtape, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            etape: action.etape
        }
    })),
    on(setUid, (state, action) => ({
        ...state,
        uid: action.uid
    })),
    on(setSession, (state, action) => ({
        ...state,
        ...{
            enveloppeInfos: {
                ...state.enveloppeInfos,
                session: action
            }
        }
    })),
    on(demandeContactEnveloppeExpiree, (state) => ({
        ...state,
        loadingDemandeContactEnveloppeExpiree: true,
        demandeContactEnveloppeExpireeError: null
    })),
    on(demandeContactEnveloppeExpireeSuccess, (state) => ({
        ...state,
        loadingDemandeContactEnveloppeExpiree: false,
        demandeContactEnveloppeExpireeSuccess: true
    })),
    on(demandeContactEnveloppeExpireeError, (state, action) => ({
        ...state,
        loadingDemandeContactEnveloppeExpiree: false,
        demandeContactEnveloppeExpireeError: action.error
    })),
    on(pushEnveloppeMobileToolbarState, (state, action) => ({
        ...state,
        mobileToolbarState: [...state.mobileToolbarState, action]
    })),
    on(popEnveloppeMobileToolbarState, (state) => {
        const toolbarState = [...state.mobileToolbarState]; // On duplique le state actuel
        toolbarState.pop(); // On supprime le dernier élément

        // On retourne le state mis à jour

        return {
            ...state,
            mobileToolbarState: toolbarState
        }
    }),

    on(updateForms, (state) => ({
        ...state,
        loadingFormUpdate: true,
        errorFormUpdate: undefined
    })),
    on(updateFormsSuccess, (state, action) => ({
        ...state,
        loadingFormUpdate: false,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            formulaires: action.inputData
        }
    })),
    on(updateFormsError, (state, action) => ({
        ...state,
        loadingFormUpdate: false,
        errorFormUpdate: action
    })),
    on(setEtatEnveloppe, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            enveloppe: {
                ...state.enveloppeInfos.enveloppe,
                etat: action.etat
            }
        }
    })),
    on(setFichierRead, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            fichiers: state.enveloppeInfos.fichiers.map(fichier => {
                if (fichier.id === action.fichier.id) {
                    return {
                        ...fichier,
                        lu: true
                    }
                }

                return fichier;
            })
        }
    })),
    on(setFichierOpened, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            fichiers: state.enveloppeInfos.fichiers.map(fichier => {
                if (fichier.id === action.fichier.id) {
                    return {
                        ...fichier,
                        ouvert: true
                    }
                }

                return fichier;
            })
        }
    })),

    on(setHasSigned, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            hasSigned: action.hasSigned
        }
    })),

    on(setSessionRefreshing, (state, action) => ({
        ...state,
        sessionRefreshing: action.sessionRefreshing
    })),

    on(setIsSignataire, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            isSignataire: action.isSignataire
        }
    })),

    on(updateSignataireEnveloppe, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            signataire: action.signataire
        }
    })),

    on(updateSignatairesEnveloppe, (state, action) => ({
        ...state,
        enveloppeInfos: {
            ...state.enveloppeInfos,
            signataires: action.signataires
        }
    })),
);

export function enveloppeReducer(
    state: EnveloppeState | undefined,
    action: Action
): EnveloppeState {
    return reducer(state, action);
}
