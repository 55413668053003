import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EnveloppeService} from "./enveloppe.services";
import {
    demandeContactEnveloppeExpiree,
    demandeContactEnveloppeExpireeError,
    demandeContactEnveloppeExpireeSuccess,
    loadEnveloppeAction,
    loadEnveloppeErrorAction,
    loadEnveloppeSuccessAction, setFichierOpened, setFichierRead, setIsSignataire, setMaintenanceEnveloppe,
    setSessionUid,
    updateForms,
    updateFormsError,
    updateFormsSuccess, updateSignataireEnveloppe, updateSignatairesEnveloppe
} from "./enveloppe.actions";
import {of, switchMap} from "rxjs";
import {catchError, filter, map, take, tap, withLatestFrom} from "rxjs/operators";
import {selectEnveloppeInfos} from "./enveloppe.selectors";
import {Store} from "@ngrx/store";

@Injectable()
export class EnveloppeEffects {

    constructor(
        private actions$: Actions,
        private enveloppeService: EnveloppeService,
        private store: Store
    ) {
    }

    load = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadEnveloppeAction),
                switchMap(action => {
                    return this.enveloppeService.getInfosEnveloppe(action.uid)
                        .pipe(
                            map(response => {
                                // Detection que l'enveloppe doit passer en mode maintenance - Serveur Agence down
                                if (response?.data?.enveloppe?.maintenance) {
                                    this.store.dispatch(setMaintenanceEnveloppe({maintenanceEnveloppe: true}));
                                }
                                return loadEnveloppeSuccessAction(response)
                            }),
                            tap(response => {
                                if (!!response.data.session) {
                                    this.enveloppeService.setSessionUid(response.data.session.uid, action.uid);
                                    return setSessionUid({uid: response.data.session.uid})
                                }
                            }),
                            catchError(err => {
                                return of(loadEnveloppeErrorAction({error: err}))
                            })
                        )
                }),
                catchError(err => {
                    return of(loadEnveloppeErrorAction({error: err}))
                })
            ),
        {dispatch: true}
    );

    demandeContactExpiree = createEffect(
        () =>
            this.actions$.pipe(
                ofType(demandeContactEnveloppeExpiree),
                switchMap(action => {
                    return this.enveloppeService.demandeContactEnveloppeExpiree(action.uid, action.comment)
                        .pipe(
                            map(response => {
                                return demandeContactEnveloppeExpireeSuccess(null)
                            }),
                            catchError(err => {
                                return of(demandeContactEnveloppeExpireeError(err))
                            })
                        )
                }),
                catchError(err => {
                    return of(demandeContactEnveloppeExpireeError(err))
                })
            )
    );

    updateForms = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateForms),
                switchMap(action => {
                    return this.enveloppeService.updateForms(action.data, action.uid)
                        .pipe(
                            map(response => {
                                // En cas de retour des signataire = modification des informations avec potentielle repositionnement
                                // du destinataire courant comme signataire
                                if (response.data.signataires !== undefined) {
                                    if (response.data.signataire !== undefined) {
                                        this.store.dispatch(updateSignataireEnveloppe({signataire: response.data.signataire}));
                                    } else {
                                        this.store.dispatch(updateSignataireEnveloppe({signataire: null}));
                                    }
                                    this.store.dispatch(updateSignatairesEnveloppe({signataires: response.data.signataires}));

                                    let isUserDestinataire = false;
                                    for (const signataire of response.data.signataires) {
                                        if (signataire.email === response.data.destinataire.email) {
                                            isUserDestinataire = true;
                                        }
                                    }
                                    this.store.dispatch(setIsSignataire({isSignataire: isUserDestinataire}));
                                }

                                return updateFormsSuccess({inputData: response.data.formulaires});
                            }),
                            catchError(err => {
                                return of(updateFormsError(err));
                            })
                        )
                }),
                catchError(err => {
                    return of(updateFormsError(err));
                })
            )
    )

    fichierRead = createEffect(
        () => this.actions$.pipe(
            ofType(setFichierRead),
            withLatestFrom(this.store.select(selectEnveloppeInfos)),
            filter(([action, infos]) => !!infos && !infos.lecture_seule_ac),
            switchMap(([action, _]) => {
                return this.enveloppeService.setFichierRead(action.uid, action.fichier.id)
            })
        ),
        {dispatch: false}
    );

    fichierOpened = createEffect(
        () => this.actions$.pipe(
            ofType(setFichierOpened),
            withLatestFrom(this.store.select(selectEnveloppeInfos)),
            filter(([action, infos]) => !!infos && !infos.lecture_seule_ac),
            switchMap(([action, _]) => {
                return this.enveloppeService.setFichierOpened(action.uid, action.fichier.id)
            })
        ),
        {dispatch: false}
    );
}
